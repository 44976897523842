angular.module('app')
    .controller('bookingMessageDriverController',
        ["$scope", "$uibModalInstance", "$log", "$timeout", "$location", "$anchorScroll", "booking", "bookingDecoratorService", "bookingService", "Notification", function ($scope, $uibModalInstance, $log, $timeout, $location, $anchorScroll, booking, bookingDecoratorService, bookingService, Notification) {

            $scope.driverMsgModel = {
                booking: booking,
                loading: false,
                message: ''
            };

            var FIFTEEN_SECONDS = 5 * 1000;
            var pollTimer;

            (function init() {
                bindLoaderStateEvents();
                poll();

                $timeout(function () {
                    // potentially should be in a directive, but seems like overkill
                    angular.element('#message').focus();
                }, 0);

            })();

            function bindLoaderStateEvents() {
                $scope.$on('loader_show', function () {
                    $scope.driverMsgModel.loading = true;
                });

                $scope.$on('loader_hide', function () {
                    $scope.driverMsgModel.loading = false;
                });

                $scope.$on('$destroy', function () {
                    $log.debug('Killing the timer');
                    cancelPollTimeout();
                });
            }

            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.sendDriverMessage = function () {

                var booking = angular.copy($scope.driverMsgModel.booking);
                booking.message = $scope.driverMsgModel.message;

                $log.info('Sending the following message: %o', booking);

                bookingService.sendDriverMessage(booking)
                    .then(function () {
                        Notification.info({message: 'Your message has been sent to the driver', delay: 5000});
                        $scope.driverMsgModel.message = '';
                        listBookings();
                    }).catch(function (error) {
                    $log.error("failed to send message to driver. Error %o", error);
                    Notification.info({message: 'Unable to send your message to the driver', delay: 5000});
                });
            };

            function scrollToBottomOfMsgs() {
                $timeout(function () {
                    $location.hash('bottom');
                    $anchorScroll();
                }, 1000);
            }

            function poll() {

                listBookings().finally(function () {
                    setPollTimeout();
                });
            }

            function listBookings() {
                return bookingService.listActiveBookings().then(function (bookings) {

                    $log.error('### bookings ###: %o, %o', bookings, $scope.driverMsgModel.booking);

                    $scope.driverMsgModel.booking = _.find(bookings, function (booking) {
                        return ($scope.driverMsgModel.booking.bookingReference === booking.bookingReference);
                    });

                    scrollToBottomOfMsgs();

                }).catch(function () {
                    $log.error("Failed to poll List Bookings");
                });
            }

            function setPollTimeout() {
                cancelPollTimeout();
                pollTimer = $timeout(poll, FIFTEEN_SECONDS);
            }

            function cancelPollTimeout() {
                if (pollTimer) {
                    $timeout.cancel(pollTimer);
                }
            }
        }]);
